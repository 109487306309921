<template>
  <div class="size-selector-panel">
    <div class="size-guide" > <!--:class="{'visibility-hidden-sizeguide': isUni}"-->
      <i class="icomoon-icon icon-guida_taglie"></i>
      <span class="btn btn-none bcm-link bcm-link-underline-primary" @click.prevent="onClickSizeGuide()">{{$t('size-guide')}}</span>
    </div>
    <ul class="sizes">
      <li
        v-for="(option, index) in sortByStatic(options)" :key="index" v-show="isEnable(option)"
        :class="{'selected': isSelected(option)}">
        <div class="size">
          <span v-if="option.in_stock && option.enabled" @click.prevent="onClickSize(option)" class="label">{{option.label}}</span>
          <span v-else class="label disabled">{{option.label}}</span>
          <size-selector-extra-label
            :isComingSoon="isComingSoon"
            :isBackInStock="option.is_backinstock === 1"
            :onlyOneLeft="option.quantity === 1"
            :outOfStock="option.quantity <= 0"
            :isPreOrder="option.is_pre_order">
          </size-selector-extra-label>
        </div>
        <button
          v-if="option.is_pre_order"
          @click.prevent="onClickSize(option)"
          class="btn btn-none bcm-link bcm-link-underline-primary">
            {{ $t('productPage.pre-order') }}
        </button>
        <button
          v-if="option.is_backinstock === 1 && !option.in_stock"
          @click.prevent="onClickNotifyMe(option)"
          class="btn btn-none bcm-link bcm-link-underline-primary">
            {{ $t('productPage.backInStockCta') }}
        </button>
        <button
          v-else-if="!option.in_stock"
          @click.prevent="onClickRequestYourSize(option)"
          class="btn btn-none bcm-link bcm-link-underline-primary">
            {{ $t('request-your-size') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import SizeSelectorExtraLabel from './SizeSelectorExtraLabel'
import find from 'lodash/find'
import { EventBus } from '@/helpers'
import { getVariantByFilter } from 'theme/helpers'
import { ManageAndSortAggregrateSizeFilter } from 'theme/store/modules/listing/listing-utils'

export default {
  name: 'SizeSelectorPanel',
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({})
    },
    filterActive: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true,
      default: () => {}
    },
    selectedOption: {
      type: Object,
      required: false,
      default: () => {}
    },
    isComingSoon: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // TODO: only for pre order test
    /* if (this.options[0]) {
      this.options[0].is_pre_order = 1
    } */
  },
  computed: {
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    getSizesAvailable () {
      let sizes = []
      if (this.$store.state.product.current) {
        const product = this.$store.state.product.current
        sizes = find(product.configurable_options, { 'attribute_code': 'size' })
        if (sizes) {
          sizes = sizes.values
        }
      }
      return sizes.filter(size => size.quantiry)
    },
    isUni () {
      let allVbYf = getVariantByFilter('size', this.filterActive, this.product.configurableParent, false)
      return allVbYf.length === 1
    },
    tmpVariants () {
      return this.$store.state.product.tmpProdSelected
    }
  },
  methods: {
    // onClickNotifyMe (option) {
    //   const _this = this
    //   // open right navigation
    //   const componentToLoad = import('theme/components/Product/NotifyMe/NotifyMe')
    //   let productVariant = this.$store.state.product.current.variants ? this.$store.state.product.current.variants : this.$store.state.quickBuy.currentProduct.variants
    //   let productGrouped = this.$store.state.product.tmpProdSelected
    //   let variantSelected = productVariant.filter(item => item.attributes[1].value_index === option.value_index).filter(item => item.attributes[0].value_index === productGrouped[0].color)
    //   this.$store.dispatch('ui/loadRightActiveComponent', {
    //     component: componentToLoad,
    //     props: {
    //       product: _this.product,
    //       variant: variantSelected
    //     },
    //     propsRightNavigation: {
    //       title: _this.$t('productPage.backInStock')
    //     }
    //   })
    // },
    onClickNotifyMe (option) {
      const _this = this
      const componentToLoad = import('theme/components/Product/NotifyMe/NotifyMe')
      let productVariant = this.$store.state.product.current.variants ? this.$store.state.product.current.variants : this.$store.state.quickBuy.currentProduct.variants
      let productGrouped = this.$store.state.product.tmpProdSelected
      let variantSelected = productVariant.filter(item => item.attributes[1].value_index === option.value_index).filter(item => item.attributes[0].value_index === productGrouped[0].color)
      // close right navigation
      this.$store.commit('ui/setRightActiveComponent', {})
      this.$store.dispatch('ui/showDynamicModalComponent', {
        component: componentToLoad,
        propsBootstrapVueModal: {
          'hide-footer': true,
          'hide-header': false,
          'modal-class': 'modal-notify-me',
          'header-close-content': '<i class="icomoon-icon icon-x"></i>',
          centered: true
        },
        props: {
          title: _this.$t('productPage.backInStock'),
          // product: _this.productSelected[0],
          product: variantSelected[0],
          hasPrivacyCheckbox: true
        }
      })
    },
    onClickSizeGuide () {
      const _this = this
      const componentToLoad = import('theme/components/Product/SizeGuide')
      this.$store.dispatch('ui/loadRightActiveComponent', {
        component: componentToLoad,
        props: {
          product: _this.product
        }
      })
      window.dataLayer.push({
        event: 'product_action',
        item_id: this.product.id,
        item_name: this.product.name,
        event_detail: 'size_guide'
      })
    },
    onClickRequestYourSize (option) {
      const _this = this
      // open right navigation
      const componentToLoad = import('theme/components/Product/SizeRequest')
      this.$store.dispatch('ui/loadRightActiveComponent', {
        component: componentToLoad,
        props: {
          product: _this.product,
          filterActive: this.filterActive,
          sizesAvalailable: _this.options.filter(opt => opt.quantity <= 0 && opt.is_backinstock === 0),
          selectedOption: option
        },
        propsRightNavigation: {
          title: _this.$t('sizeRequest.title')
        }
      })
    },
    onClickSize (option) {
      if (option && option.in_stock) {
        EventBus.$emit('sizeSelectorPanel:selected', option)
        this.$store.commit('ui/setRightActiveComponent', {})
      }
    },
    isSelected (option) {
      if (option && option.value) {
        if (this.selectedOption.value && option.value === this.selectedOption.value) {
          return true
        }
      }
      return false
    },
    isEnable (option) {
      let filtersVariants = this.tmpVariants
      let variants = this.product.variants
      let setVariant = []
      for (let i in variants) {
        for (let j in filtersVariants) {
          if (variants[i].product.sku === filtersVariants[j].sku) {
            setVariant.push(variants[i])
          }
        }
      }
      for (let x in setVariant) {
        for (let y in setVariant[x].attributes) {
          if (setVariant[x].attributes[y].value_index === option.value_index && setVariant[x].product.enabled === false) {
            return false
          }
        }
      }
      return true
    },
    sortByStatic (options) {
      options = options.map(option => {
        return { ...option, html: this.getFirstPartOfLabelSize(option.label) }
      })
      return ManageAndSortAggregrateSizeFilter(options)
    },
    getFirstPartOfLabelSize (label) {
      let split = label.split(' ')
      if (split.length === 0) return label
      return split[0].toUpperCase()
    }
  },
  components: {
    SizeSelectorExtraLabel
  }
}
</script>
